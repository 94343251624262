<template>
  <div class="storeBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          报表列表
        </div>
        <div class="card-hd-right">
          <Refresh @refreshCall="getAllReportList"></Refresh>
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 模糊搜索 -->
        <SearchBox
          :searchVal="baseData.searchVal"
          placeholder="请输入报表名称"
          @searchCall="searchHandle"
        ></SearchBox>
        <!-- 下拉选择 -->
        <SelectDown
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <!-- 弹窗 -->
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('goods')">新增报表</el-button>
        <!-- 弹窗 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="SimpleDialogClose"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  新增报表
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabs.currentIndex"
                    @tab-click="tabsListHandle">
                    <el-tab-pane
                      v-for="item in tabs.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      @scroll="doScroll"
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormRef"
                        :model="ruleForm"
                        status-icon
                        :rules="rules"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="报表名称" prop="templateName">
                                <el-input v-model="ruleForm.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="请填写报表名称"
                                  show-word-limit
                                  clearable
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              有效订单设置
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="有效订单不包括：" prop="trueOrderSelectArr">
                                <el-checkbox-group
                                  v-model="ruleForm.trueOrderSelectArr"
                                >
                                  <el-checkbox
                                    v-for="item in ruleForm.trueOrderTypeSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                  />
                                </el-checkbox-group>
                              </el-form-item>
                            </div>
                            <div class="card-bd-item is-alone greylight2">
                              （ 有效订单 = 【全部订单】
                              <span v-for="item in ruleForm.trueOrderSelectArr" :key="item">
                                - {{ item }}
                              </span>
                              ）
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem2">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              人工/快递支出设置
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item">
                              <el-form-item label="预估快递费/单" prop="globalExpressdeliveryFee">
                                <el-input v-model="ruleForm.globalExpressdeliveryFee"
                                  type="number"
                                  :min="0"
                                  :max="99999"
                                  :precision="2"
                                  step="0.1"
                                  placeholder="请设置费用"
                                  show-word-limit
                                  @input="inputNumberCheck($event, 'globalExpressdeliveryFee')"
                                  @change="changeNumberCheck($event, 'globalExpressdeliveryFee')"
                                >
                                  <template #append>元</template>
                                </el-input>
                              </el-form-item>
                              <el-popover
                                placement="right"
                                :width="200"
                                trigger="hover"
                                content="每单预估快递费(参考：0.1)"
                              >
                                <template #reference>
                                  <el-icon class="card-bd-info-icon"><InfoFilled /></el-icon>
                                </template>
                              </el-popover>
                            </div>
                            <div class="card-bd-item">
                              <el-form-item label="预估人工费/单" prop="globalLaborFee">
                                <el-input v-model="ruleForm.globalLaborFee"
                                  type="number"
                                  :min="0"
                                  :max="99999"
                                  :precision="2"
                                  step="0.1"
                                  placeholder="请设置费用"
                                  show-word-limit
                                  @input="inputNumberCheck($event, 'globalLaborFee')"
                                  @change="changeNumberCheck($event, 'globalLaborFee')"
                                >
                                  <template #append>元</template>
                                </el-input>
                              </el-form-item>
                              <el-popover
                                placement="right"
                                :width="200"
                                trigger="hover"
                                content="每单预估人工费(参考：0.1)"
                              >
                                <template #reference>
                                  <el-icon class="card-bd-info-icon"><InfoFilled /></el-icon>
                                </template>
                              </el-popover>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="submitForm(ruleFormRef)">保存</el-button>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <div v-if="item.prop == 'handle'">
              <el-button size="small" type="primary" @click="editReportAnalysisHandle(scope.row.id)">编辑</el-button>
              <el-button size="small" type="danger" @click="delReportAnalysisHandle(scope.row.id)">删除</el-button>
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无报表">
              <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('goods')">新增报表</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import SelectDown from '@/components/SelectDown/index.vue'
import SearchBox from '@/components/SearchBox/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { onMounted, reactive, watch, ref, nextTick } from 'vue'
import { ElMessageBox } from 'element-plus'
import { reportAnalysisPageList, reportAnalysisAdd, reportAnalysisDel } from '@/api/report.js'
import { timeToDate } from '@/utils/unitTransform.js'

const baseData = reactive({
  selectTimes: '',
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: '模板名称', prop: 'name', minWidth: '200' },
    { label: '创建时间', prop: 'created_at', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  tableListData: [],
  currentPage: 1,
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  SimpleDialogShow: false,
  searchVal: ''
})
// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
const tabs = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: '有效订单设置', name: 1 },
    { title: '人工/快递支出', name: 2 }
  ]
})
// elementPlus滚动条缓动
const scrollToTopTransition = (willGo, nowSite = 0, duration = 300) => {
  let passedTime = 0
  const originTime = Date.now()
  // 贝塞尔缓动式由0到1
  const easeingFunction = (t) => {
    if (t < 0.5) {
      return (4 * t * t * t)
    } else {
      return ((t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    }
  }
  const _scrollToTop = () => {
    if (passedTime < duration) {
      passedTime = Date.now() - originTime
      // duration(默认300毫秒)内重复执行
      requestAnimationFrame(_scrollToTop)
      // 当前位置nowSite到预期位置willGo的真实滚动距离
      const trueDistance = (nowSite + (willGo - nowSite) * (easeingFunction(passedTime / duration)))
      goodsFormScoll.value.setScrollTop(trueDistance)
    }
  }
  _scrollToTop()
}
// tabs点击事件
const tabsListHandle = (e) => {
  const indexNum = Number(e.index)
  const willGoCard = document.querySelector(`#cardItem${indexNum}`)
  willGoCard.classList.add('willFocus')
  setTimeout(() => {
    willGoCard.classList.remove('willFocus')
  }, 2000)
  scrollToTopTransition(tabs.list[indexNum].offsetTop - 12, baseData.scrollSite)
}
// tabs 监听滚动
const doScroll = (event) => {
  const list = tabs.list
  baseData.scrollSite = event.scrollTop
  for (const i in list) {
    console.log(tabs.list[i].offsetTop, '--', baseData.scrollSite)
    const { offsetTop, name } = tabs.list[i]
    if (offsetTop >= baseData.scrollSite) {
      tabs.currentIndex = name
      break
    }
  }
}
// 获取cradItem锚点距顶坐标
const getCardItemSite = () => {
  nextTick(() => {
    const list = tabs.list
    for (const i in list) {
      tabs.list[i].offsetTop = document.querySelector(`#cardItem${i}`).offsetTop
    }
  })
}

// input @input 键入数字校验
const inputNumberCheck = (e, ruleFormKey) => {
  if (Number(e) > 9999) {
    ruleForm[ruleFormKey] = 9999
  }
  if (Number(e) < 0) {
    ruleForm[ruleFormKey] = 0
  }
}
// input @change 键入数字校验
const changeNumberCheck = (e, ruleFormKey) => {
  ruleForm[ruleFormKey] = Number(e)
}
// 搜索事件
const searchHandle = (type, searchBox) => {
  baseData.searchVal = type ? searchBox.searchVal : ''
  getReportAnalysisPageList()
}

// tabs currentIndex监听
watch(() => tabs.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite()
})

// 报表表单 action
// 表单 信息
const ruleForm = reactive({
  orderStatus: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，待收货', '已发货，退款成功', '已收货', '已收货，退款成功'],
  templateName: '', // 模板名称
  trueOrderSelectArr: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，退款成功', '已收货，退款成功'], // 有效订单=减去对应类型(减法)
  trueOrderTypeSelect: [
    {
      value: '1',
      label: '待付款'
    },
    {
      value: '2',
      label: '已取消'
    },
    {
      value: '3',
      label: '待发货'
    },
    {
      value: '4',
      label: '未发货，退款成功'
    },
    {
      value: '5',
      label: '已发货，待收货'
    },
    {
      value: '6',
      label: '已发货，退款成功'
    },
    {
      value: '7',
      label: '已收货'
    },
    {
      value: '8',
      label: '已收货，退款成功'
    }
  ],
  globalLaborFee: '', // 全局人工费
  globalExpressdeliveryFee: '' // 全局快递费
})
// 表单 验证相关
const ruleFormRef = ref()
// 表单 模板名校验规则
const checkTemplateName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入模板名称'))
  } else {
    callback()
  }
}
// 表单 费用校验规则
const checkFee = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请设置费用'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rules = reactive({
  templateName: [{ required: true, validator: checkTemplateName, trigger: 'blur' }],
  globalLaborFee: [{ required: true, validator: checkFee, trigger: 'blur' }],
  globalExpressdeliveryFee: [{ required: true, validator: checkFee, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleForm)
      // 收集多选id
      const lastSelectIdArr = []
      for (const i in ruleForm.trueOrderSelectArr) {
        for (const j in ruleForm.trueOrderTypeSelect) {
          const defaultArr = ruleForm.trueOrderTypeSelect[j]
          const selectItem = ruleForm.trueOrderSelectArr[i]
          if (defaultArr.label === selectItem) {
            lastSelectIdArr.push(defaultArr.value)
            break
          }
        }
      }
      addReportAnalysisHandle(ruleForm, lastSelectIdArr)
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl) => {
  if (!formEl) return
  ElMessageBox.confirm(
    '重置后所有未保存的内容均会丢失，是否继续？',
    {
      confirmButtonText: '重置',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    formEl.resetFields()
  }).catch(() => {
  })
}

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  baseData.SimpleDialogShow = true
}

// 获取模板分页列表
const getReportAnalysisPageList = async () => {
  const resData = await reportAnalysisPageList({
    template_name: baseData.searchVal,
    page: baseData.currentPage,
    page_size: baseData.pageSize
  })
  for (const i in resData.data) {
    const dataItem = resData.data[i]
    if (dataItem?.created_at) {
      dataItem.created_at = timeToDate(dataItem?.created_at)
    }
  }
  baseData.tableListData = resData.data
  baseData.pageTotal = resData.total
  console.log('getReportAnalysisPageList', resData)
}
// 新增模板接口
const addReportAnalysisHandle = async (ruleForm, lastSelectIdArr) => {
  await reportAnalysisAdd({
    template_name: ruleForm.templateName,
    invalid_order_status: lastSelectIdArr,
    expected_express_fee: ruleForm.globalExpressdeliveryFee,
    expected_labor_cost: ruleForm.globalLaborFee
  })
  getReportAnalysisPageList()
  baseData.SimpleDialogShow = false
}
// 编辑模板接口
const editReportAnalysisHandle = (id) => {
  console.log('editReportAnalysisHandle 编辑模板接口', id)
}
// 删除模板事件 — 询问
const delReportAnalysisHandle = (id) => {
  ElMessageBox.confirm(
    '删除后该模板将不可再次使用，是否继续？',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    delApiHandle(id)
  }).catch(() => {
  })
}
// 删除模板事件 — 接口
const delApiHandle = async (id) => {
  await reportAnalysisDel({
    ids: [id]
  })
  getReportAnalysisPageList()
}

const getAllReportList = () => {
  getReportAnalysisPageList()
}

onMounted(() => {
  getAllReportList()
})
</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
@import '~@/styles/report/index.scss';
</style>
